<template>
  <NuxtLink
    :to="link"
    target="_blank"
    :title="
      t('links.visitAt', {
        name: cmsConfig?.name,
        socialNetwork: t(`links.${iconName}`),
      })
    "
    class="flex h-8 w-8 items-center justify-center"
  >
    <InlineSvg :name="iconName" class="h-full w-full fill-current" />
  </NuxtLink>
</template>

<script setup lang="ts">
interface Props {
  link: string
}
const props = defineProps<Props>()
const { t } = useI18n()
const cmsConfig = useCmsConfig()

const iconName = computed(() => {
  for (const socialMedia in SVG_ICON) {
    if (props.link.includes(socialMedia.toLowerCase())) {
      return SVG_ICON[socialMedia as keyof typeof SVG_ICON]
    }
  }
  return SVG_ICON.X
})
</script>

<script lang="ts">
export default {
  name: 'SocialLink',
}
</script>

<i18n>
de:
  links:
    visitAt: "{name} auf {socialNetwork} besuchen"
    facebook: "Facebook"
    twitter: "Twitter"
    tiktok: "TikTok"
    pinterest: "Pinterest"
    youtube: "YouTube"
    instagram: "Instagram"
    linkedin: "LinkedIn"
es:
  links:
    visitAt: "Visitar {name} en {socialNetwork}"
    facebook: "Facebook"
    twitter: "Twitter"
    tiktok: "TikTok"
    pinterest: "Pinterest"
    youtube: "YouTube"
    instagram: "Instagram"
    linkedin: "LinkedIn"
</i18n>
