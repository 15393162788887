<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <div
      id="layout"
      class="isolate grid min-h-dvh grid-rows-[auto,minmax(0,1fr),auto]"
    >
      <PageHeader
        class="shadow-dark/25 sticky top-0 z-50 shadow print:static"
      />

      <main id="main" class="relative">
        <MessageController />
        <div class="relative flex min-h-full flex-col">
          <slot />
        </div>
      </main>

      <PageFooter class="print:hidden" />
    </div>
  </Html>
</template>

<script setup lang="ts">
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

defineOptions({
  name: 'DefaultLayout',
})
</script>
